import { graphql, useStaticQuery } from 'gatsby'

const query = graphql`
  query Navbar {
    logo: file(relativePath: { eq: "ship-of-fools-horizontal.svg" }) {
      publicURL
    }
  }
`
export default () =>
  useStaticQuery<RecursiveNonNullable<Sof.Queries.NavbarQuery>>(query)
