import {
  ExternalLinkType,
  LanguageSelector,
  Link,
  Media,
  MediaContext,
  Navbar as SharedNavbar,
  NAVBAR_ANIMATED_ELEMENT_STYLES,
  NavbarState,
  PageContext,
  PageLinks,
  SocialMedia,
} from '@shared/ui'
import classNames from 'classnames'
import React, { FC, ReactNode, useCallback, useContext } from 'react'
import { ReactSVG } from 'react-svg'

import useSocialMediaLinks from 'sof/hooks/SocialMediaLinks'
import Pages, { getTheme, NAVBAR_LINKS } from 'sof/pages'

import useData from './query'
import useState from './state'
import styles from './styles.module.css'

const Navbar: FC = () => {
  const media = useContext(MediaContext)
  const { slug } = useContext(PageContext)
  const socialMediaLinks = useSocialMediaLinks()
  const {
    getHomeLinkWidth,
    getLanguageSelectorWidth,
    getPageLinksWidth,
    getPlatformsHalfWidth,
    getSocialMediaLinksWidth,
    homeLinkImageRef,
    homeLinkRef,
    languageSelectorRef,
    pageLinkRef,
    socialMediaLinkIconRef,
    socialMediaLinkRef,
  } = useState(socialMediaLinks.length)

  const {
    logo: { publicURL: logo },
  } = useData()

  return (
    <SharedNavbar
      anchoredClassName={styles.visibleOnMobile}
      fillRatio={
        slug === Pages.HOME && media !== Media.MOBILE ? 0.5 : undefined
      }
      getElementsWidth={useCallback(
        () =>
          getHomeLinkWidth() +
          getPlatformsHalfWidth() +
          getSocialMediaLinksWidth() +
          getPageLinksWidth() +
          getLanguageSelectorWidth(),
        [
          getHomeLinkWidth,
          getPlatformsHalfWidth,
          getSocialMediaLinksWidth,
          getPageLinksWidth,
          getLanguageSelectorWidth,
        ]
      )}
      getTheme={getTheme}
      left={useCallback<(state: NavbarState, burgerized: boolean) => ReactNode>(
        () =>
          slug !== Pages.HOME && (
            <Link
              ref={homeLinkRef}
              className={classNames(styles.link, styles.homeLink)}
              to="/"
            >
              <ReactSVG
                afterInjection={homeLinkImageRef}
                className={styles.homeLinkImage}
                src={logo}
              />
            </Link>
          ),
        [homeLinkImageRef, homeLinkRef, logo, slug]
      )}
      right={useCallback<
        (state: NavbarState, burgerized: boolean) => ReactNode
      >(
        (state, burgerized) => (
          <>
            {burgerized ? (
              <div className={styles.socialMediaLinks}>
                <SocialMedia
                  focusable={state === NavbarState.FULLSCREEN}
                  links={socialMediaLinks}
                  type={ExternalLinkType.FABS}
                />
              </div>
            ) : (
              <SocialMedia
                ref={socialMediaLinkRef}
                className={classNames(
                  styles.link,
                  styles.socialMediaLink,
                  NAVBAR_ANIMATED_ELEMENT_STYLES
                )}
                imageRef={socialMediaLinkIconRef}
                links={socialMediaLinks}
                type={ExternalLinkType.ICONS}
              />
            )}

            <PageLinks
              ref={burgerized ? undefined : pageLinkRef}
              className={classNames(
                styles.link,
                NAVBAR_ANIMATED_ELEMENT_STYLES
              )}
              focusable={
                burgerized ? state === NavbarState.FULLSCREEN : undefined
              }
              links={NAVBAR_LINKS}
            />

            <LanguageSelector
              ref={burgerized ? undefined : languageSelectorRef}
              className={styles.languageSelector}
              containerClassName={classNames(
                styles.link,
                NAVBAR_ANIMATED_ELEMENT_STYLES
              )}
              focusable={
                burgerized ? state === NavbarState.FULLSCREEN : undefined
              }
              hollow
            />
          </>
        ),
        [
          languageSelectorRef,
          pageLinkRef,
          socialMediaLinkIconRef,
          socialMediaLinkRef,
          socialMediaLinks,
        ]
      )}
    />
  )
}

export default Navbar
