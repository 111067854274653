import 'sof/global-styles.css'

import { type TPageContext, PageLayout as SharedPageLayout } from '@shared/ui'
import React, { FC, ReactNode, useCallback } from 'react'

import TRANSLATIONS from 'sof/translations'

import LegalSection from './LegalSection'
import Loader from './Loader'
import Navbar from './Navbar'

import './styles.cookie-consent.override.css'

interface Props {
  children: ReactNode
  pageContext: TPageContext
}

const Layout: FC<Props> = ({ children, pageContext }) => (
  <SharedPageLayout
    loader={useCallback(
      () => (
        <Loader />
      ),
      []
    )}
    pageContext={pageContext}
    translations={TRANSLATIONS}
  >
    <Navbar />
    {children}
    <LegalSection />
  </SharedPageLayout>
)

export default Layout
