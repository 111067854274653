import { graphql, useStaticQuery } from 'gatsby'

const query = graphql`
  query SocialMediaLinks {
    site {
      siteMetadata {
        externalUrls {
          discord
          x
        }
      }
    }
  }
`

export default () =>
  useStaticQuery<RecursiveNonNullable<Sof.Queries.SocialMediaLinksQuery>>(query)
