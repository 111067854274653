import { graphql, useStaticQuery } from 'gatsby'

const query = graphql`
  query Shared {
    carouselNavigationIcon: file(
      relativePath: { eq: "carousel-navigation-icon.svg" }
    ) {
      publicURL
    }

    collapsibleSectionIcon: file(
      relativePath: { eq: "collapsible-section-icon.svg" }
    ) {
      publicURL
    }

    discord: file(relativePath: { eq: "discord.svg" }) {
      publicURL
    }

    facebook: file(relativePath: { eq: "facebook.svg" }) {
      publicURL
    }

    flourish: file(relativePath: { eq: "flourish.svg" }) {
      publicURL
    }

    github: file(relativePath: { eq: "github.svg" }) {
      publicURL
    }

    instagram: file(relativePath: { eq: "instagram.svg" }) {
      publicURL
    }

    kickstarterEndingSoonBannerEn: file(
      relativePath: { eq: "kickstarter-banner-ending-soon-en.png" }
    ) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED, width: 198, height: 183)
      }
    }

    kickstarterEndingSoonBannerFr: file(
      relativePath: { eq: "kickstarter-banner-ending-soon-fr.png" }
    ) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED, width: 198, height: 183)
      }
    }

    kickstarterJustFundedBannerEn: file(
      relativePath: { eq: "kickstarter-banner-just-funded-en.png" }
    ) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED, width: 198, height: 183)
      }
    }

    kickstarterJustFundedBannerFr: file(
      relativePath: { eq: "kickstarter-banner-just-funded-fr.png" }
    ) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED, width: 198, height: 183)
      }
    }

    kickstarterJustLaunchedBannerEn: file(
      relativePath: { eq: "kickstarter-banner-just-launched-en.png" }
    ) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED, width: 214, height: 183)
      }
    }

    kickstarterJustLaunchedBannerFr: file(
      relativePath: { eq: "kickstarter-banner-just-launched-fr.png" }
    ) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED, width: 214, height: 183)
      }
    }

    kickstarterNearlyFundedBannerEn: file(
      relativePath: { eq: "kickstarter-banner-nearly-funded-en.png" }
    ) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED, width: 198, height: 183)
      }
    }

    kickstarterNearlyFundedBannerFr: file(
      relativePath: { eq: "kickstarter-banner-nearly-funded-fr.png" }
    ) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED, width: 198, height: 183)
      }
    }

    linkedin: file(relativePath: { eq: "linkedin.svg" }) {
      publicURL
    }

    nintendoSwitch: file(relativePath: { eq: "nintendo-switch.svg" }) {
      publicURL
    }

    ogImage: file(relativePath: { eq: "og-image.png" }) {
      childImageSharp {
        original {
          height
          width
        }
      }
      publicURL
    }

    playStation: file(relativePath: { eq: "play-station.svg" }) {
      publicURL
    }

    steam: file(relativePath: { eq: "steam.svg" }) {
      publicURL
    }

    x: file(relativePath: { eq: "x.svg" }) {
      publicURL
    }

    xbox: file(relativePath: { eq: "xbox.svg" }) {
      publicURL
    }

    site {
      siteMetadata {
        baseUrl
        copyright
        defaultLocale
        descriptions {
          en
          fr
        }
        keywords {
          en
          fr
        }
        locales
        titles {
          en
          fr
        }
      }
    }
  }
`

export default () =>
  useStaticQuery<RecursiveNonNullable<Sof.Queries.SharedQuery>>(query)
