import { graphql, useStaticQuery } from 'gatsby'

const query = graphql`
  query Loader {
    loader: file(relativePath: { eq: "loader.png" }) {
      childImageSharp {
        gatsbyImageData(layout: FIXED, width: 100)
      }
    }
  }
`
export default () =>
  useStaticQuery<RecursiveNonNullable<Sof.Queries.LoaderQuery>>(query)
