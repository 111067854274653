import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import React, { FC } from 'react'
import { useIntl } from 'react-intl'

import useData from './query'
import styles from './styles.module.css'

const Loader: FC = () => {
  const { formatMessage } = useIntl()

  const { loader: loaderData } = useData()
  const loader = getImage(loaderData)

  if (!loader) {
    return null
  }

  return (
    <GatsbyImage
      alt={formatMessage({ id: 'loader.alt' })}
      className={styles.loader}
      image={loader}
      loading="eager"
    />
  )
}

export default Loader
