import { Media, Theme } from '@shared/ui'

enum Pages {
  HOME = '/',

  /* eslint-disable typescript-sort-keys/string-enum */
  PRESS_KIT = '/press-kit/ship-of-fools/',
  /* eslint-enable typescript-sort-keys/string-enum */
}

export const NAVBAR_LINKS = Object.values(Pages)
  .filter(page => page !== Pages.HOME)
  .map(page => ({
    isStatic: page === Pages.PRESS_KIT,
    to: page,
    translationId: `navbar.${page
      .replace(/^\/|\/$/g, '')
      .split('/')
      .join('_')}_link`,
  }))

export function getTheme(slug: string, media: Media) {
  switch (slug) {
    case Pages.HOME:
      if (media === Media.MOBILE) {
        return Theme.DARK
      }

      return Theme.TRAILER
    default:
      return Theme.LIGHT
  }
}

export default Pages
