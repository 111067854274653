import classNames from 'classnames'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import React, { FC, useContext, useMemo } from 'react'
import { useIntl } from 'react-intl'

import { useData } from 'ui/contexts/InjectionContext'
import { Locale } from 'ui/contexts/InternationalizationContext'
import { LoadingContext } from 'ui/contexts/LoadingContext'
import { Media, MediaContext } from 'ui/contexts/MediaContext'
import { PageContext } from 'ui/contexts/PageContext'

import styles from './styles.module.css'

export enum Stage {
  ENDING_SOON = 'ending_soon',
  JUST_FUNDED = 'just_funded',
  JUST_LAUNCHED = 'just_launched',
  NEARLY_FUNDED = 'nearly_funded',
}

interface Props {
  href?: string
  simplifiedOnMobile?: boolean
  stage: Stage
}

const KickstarterBanner: FC<Props> = ({
  href,
  simplifiedOnMobile = false,
  stage,
}) => {
  const [register, notify] = useContext(LoadingContext)
  const media = useContext(MediaContext)
  const { locale } = useContext(PageContext)
  const { formatMessage } = useIntl()

  const {
    kickstarterEndingSoonBannerEn: kickstarterEndingSoonBannerEnData,
    kickstarterEndingSoonBannerFr: kickstarterEndingSoonBannerFrData,
    kickstarterJustFundedBannerEn: kickstarterJustFundedBannerEnData,
    kickstarterJustFundedBannerFr: kickstarterJustFundedBannerFrData,
    kickstarterJustLaunchedBannerEn: kickstarterJustLaunchedBannerEnData,
    kickstarterJustLaunchedBannerFr: kickstarterJustLaunchedBannerFrData,
    kickstarterNearlyFundedBannerEn: kickstarterNearlyFundedBannerEnData,
    kickstarterNearlyFundedBannerFr: kickstarterNearlyFundedBannerFrData,
  } = useData()

  let bannerData

  switch (locale) {
    case Locale.EN:
      switch (stage) {
        case Stage.ENDING_SOON:
          bannerData = kickstarterEndingSoonBannerEnData
          break
        case Stage.JUST_FUNDED:
          bannerData = kickstarterJustFundedBannerEnData
          break
        case Stage.JUST_LAUNCHED:
          bannerData = kickstarterJustLaunchedBannerEnData
          break
        case Stage.NEARLY_FUNDED:
          bannerData = kickstarterNearlyFundedBannerEnData
          break
        default:
          throw new Error('Unsupported stage!')
      }

      break
    case Locale.FR:
      switch (stage) {
        case Stage.ENDING_SOON:
          bannerData = kickstarterEndingSoonBannerFrData
          break
        case Stage.JUST_FUNDED:
          bannerData = kickstarterJustFundedBannerFrData
          break
        case Stage.JUST_LAUNCHED:
          bannerData = kickstarterJustLaunchedBannerFrData
          break
        case Stage.NEARLY_FUNDED:
          bannerData = kickstarterNearlyFundedBannerFrData
          break
        default:
          throw new Error('Unsupported stage!')
      }

      break
    default:
      throw new Error('Unsupported locale!')
  }

  const banner = getImage(bannerData)
  const height = banner?.height
  const width = banner?.width
  const ratio = media === Media.MOBILE ? 0.5 : 1
  const style = useMemo(
    () => ({ height: height && height * ratio, width: width && width * ratio }),
    [height, ratio, width]
  )

  if (!banner) {
    return null
  }

  const alt = formatMessage({ id: `kickstarter_banner.${stage}` })

  return (
    <div
      className={classNames(styles.container, { [styles.interactable]: href })}
    >
      <a
        aria-label={alt}
        className={styles.square}
        href={href}
        rel="noreferrer"
        style={style}
        target="_blank"
      >
        <GatsbyImage
          alt={alt}
          className={classNames(styles.squareImage, {
            [styles.simplifiedOnMobile]: simplifiedOnMobile,
          })}
          image={banner}
          loading="eager"
          objectFit="contain"
          objectPosition="bottom left"
          onLoad={notify}
          onStartLoad={register}
        />
      </a>

      <div className={styles.banner} />
    </div>
  )
}

export default KickstarterBanner
