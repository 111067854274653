import {
  CanvasContext,
  getFontComputedStyle,
  Media,
  MediaContext,
  PageContext,
  pxValue,
} from '@shared/ui'
import { useCallback, useContext, useMemo, useRef, useState } from 'react'
import { useIntl } from 'react-intl'
import { ReactSVG } from 'react-svg'

import Pages, { NAVBAR_LINKS } from 'sof/pages'

export default (socialMediaLinksAmount: number) => {
  const homeLinkRef = useRef<HTMLAnchorElement>(null)

  const [homeLinkImageWidth, setHomeLinkImageWidth] = useState(0)
  const homeLinkImageRef = useCallback<
    NonNullable<ReactSVG['props']['afterInjection']>
  >(
    (_, element) => {
      if (element && homeLinkImageWidth === 0) {
        setHomeLinkImageWidth(element.clientWidth)
      }
    },
    [homeLinkImageWidth]
  )

  const getHomeLinkWidth = useCallback(() => {
    const homeLink = homeLinkRef.current

    if (!homeLink) {
      return 0
    }

    return homeLinkImageWidth + pxValue(getComputedStyle(homeLink).marginRight)
  }, [homeLinkImageWidth])

  const socialMediaLinkMeasurements = useRef({ marginRight: 0 })
  const socialMediaLinkRef = useRef<HTMLAnchorElement>(null)
  const [socialMediaLinkIconWidth, setSocialMediaLinkIconWidth] = useState(0)
  const socialMediaLinkIconRef = useCallback<
    NonNullable<ReactSVG['props']['afterInjection']>
  >(
    (_, element) => {
      if (element && socialMediaLinkIconWidth === 0) {
        setSocialMediaLinkIconWidth(element.clientWidth)
      }
    },
    [socialMediaLinkIconWidth]
  )

  const getSocialMediaLinksWidth = useCallback(() => {
    const socialMediaLink = socialMediaLinkRef.current

    if (socialMediaLink) {
      socialMediaLinkMeasurements.current.marginRight = pxValue(
        getComputedStyle(socialMediaLink).marginRight
      )
    }

    return (
      (socialMediaLinkIconWidth +
        socialMediaLinkMeasurements.current.marginRight) *
      socialMediaLinksAmount
    )
  }, [socialMediaLinkIconWidth, socialMediaLinksAmount])

  const pageLinkMeasurements = useRef({
    font: '',
    letterSpacing: 0,
    marginRight: 0,
  })
  const pageLinkRef = useRef<HTMLAnchorElement>(null)
  const { measureText } = useContext(CanvasContext)
  const { formatMessage } = useIntl()

  const pageLinks = useMemo(
    () =>
      NAVBAR_LINKS.map(({ translationId }) =>
        formatMessage({ id: translationId }).toUpperCase()
      ),
    [formatMessage]
  )

  const getPageLinksWidth = useCallback(() => {
    const pageLink = pageLinkRef.current

    if (pageLink) {
      pageLinkMeasurements.current = {
        font: getFontComputedStyle(pageLink),
        letterSpacing: pxValue(getComputedStyle(pageLink).letterSpacing),
        marginRight: pxValue(getComputedStyle(pageLink).marginRight),
      }
    }

    const pageLinksWidth = pageLinks.reduce(
      (accumulator, current) =>
        accumulator +
        measureText({
          font: pageLinkMeasurements.current.font,
          letterSpacing: pageLinkMeasurements.current.letterSpacing,
          text: current,
        }),
      0
    )

    return (
      pageLinksWidth +
      pageLinkMeasurements.current.marginRight * pageLinks.length
    )
  }, [measureText, pageLinks])

  const languageSelectorMeasurements = useRef({ width: 0 })
  const languageSelectorRef = useRef<HTMLAnchorElement>(null)

  const getLanguageSelectorWidth = useCallback(() => {
    const languageSelector = languageSelectorRef.current

    if (languageSelector) {
      languageSelectorMeasurements.current = {
        width: languageSelector.clientWidth,
      }
    }

    return languageSelectorMeasurements.current.width
  }, [])

  const media = useContext(MediaContext)
  const { slug } = useContext(PageContext)

  const getPlatformsHalfWidth = useCallback(() => {
    if (
      !document?.documentElement ||
      slug !== Pages.HOME ||
      media === Media.MOBILE
    ) {
      return 0
    }

    const flourishWidth = pxValue(
      getComputedStyle(document.documentElement).getPropertyValue(
        '--fika-platforms-flourish-width'
      )
    )

    return flourishWidth / 2
  }, [media, slug])

  return {
    getHomeLinkWidth,
    getLanguageSelectorWidth,
    getPageLinksWidth,
    getPlatformsHalfWidth,
    getSocialMediaLinksWidth,
    homeLinkImageRef,
    homeLinkRef,
    languageSelectorRef,
    pageLinkRef,
    socialMediaLinkIconRef,
    socialMediaLinkRef,
  }
}
